export const AWC_ENV_LIST = {
  STG: {
    name: 'STG',
    domain: 'https://sstint.22onlinegames.com',
    reportDomain: 'https://sstint.22onlinegames.com',
  },
  UAT: {
    name: 'UAT',
    domain: 'https://tttint.apihub55.com',
    reportDomain: 'https://tttint.apihub55.com',
  },
  AWCA: {
    name: 'AWCS01',
    domain: 'https://api.onlinegames22.com',
    reportDomain: 'https://fetch.onlinegames22.com',
  },
  AWC: {
    name: 'AWCS02',
    domain: 'https://api.22onlinegames.com',
    reportDomain: 'https://fetch.22onlinegames.com',
  },
  AWS03: {
    name: 'AWCS03',
    domain: 'https://gciap.usplaynet.com',
    reportDomain: 'https://gcife.usplaynet.com',
  },
  AWS06: {
    name: 'AWCS06',
    domain: 'https://fsaap.velkigames365.cc',
    reportDomain: 'https://fsafe.velkigames365.cc',
  },
  AWS08: {
    name: 'AWCS08',
    domain: 'https://ufcap.ufagames99.live',
    reportDomain: 'https://ufcfe.ufagames99.live',
  },
  AWS09: {
    name: 'Private Net',
    domain: 'https://awsap.ambbest.live',
    reportDomain: 'https://awsfe.ambbest.live',
  },
  AWS11: {
    name: 'AWCS11',
    domain: 'https://cp2ap.cc2ps.cc',
    reportDomain: 'https://cp2fe.cc2ps.cc',
  },
  AWSKR1: {
    name: 'AWSKR1',
    domain: 'https://kraap.kkrwplay.com',
    reportDomain: 'https://krafe.kkrwplay.com',
  },
}

export const GAMETYPE_LIST = ['LIVE', 'SLOT', 'FH', 'EGAME', 'TABLE', 'BINGO', 'LOTTO', 'VIRTUAL', 'ESPORTS']

export const GAMETYPE_OPTIONS = GAMETYPE_LIST.map(gameType => ({ label: gameType, value: gameType }))

export const CURRENCY_LIST = [
  'AED',
  'AUD',
  'BDT',
  'BND',
  'BRL',
  'CAD',
  'CHF',
  'CNY',
  'COP',
  'EGP',
  'ETB',
  'EUR',
  'GBP',
  'HKD',
  'IDR',
  'INR',
  'JPY',
  'KES',
  'KHR',
  'KRW',
  'KZT',
  'LAK',
  'LAKK',
  'LKR',
  'MMK',
  'MMKK',
  'MNT',
  'MOP',
  'MXN',
  'MYR',
  'NGN',
  'NOK',
  'NPR',
  'NZD',
  // 'PBU',
  'PEN',
  'PHP',
  'PKR',
  'PTI',
  'PTS',
  'PTV',
  'RUB',
  'SEK',
  'SGD',
  'THB',
  'TND',
  'TRY',
  'TZS',
  'UAH',
  'USD',
  // 'USD5',
  'VND',
  'ZAR',
  'ZMW',
  'ZWD',
]

export const CURRENCY_OPTIONS = CURRENCY_LIST.map(currency => ({ label: currency, value: currency }))

export const LANGUAGE_LIST = ['en', 'cn', 'jp', 'th', 'vn', 'kr']

export const LANGUAGE_OPTIONS = LANGUAGE_LIST.map(lang => ({ label: lang, value: lang }))
